import {
  get,
  post
}
from '../common/axiosHttp.js'

// 获取站点列表
export const getSitesList = params => get("/site/getSites", params);

// 获取阀门列表
export const getSitesIdList = params => get("/gate/getGatesBySiteId", params);

// 切换闸门
export const getGateMessage = params => get("/gate/getGateById", params);

// 获取泵站列表
export const getPumpStationMain = params => get("/pumpStation/getPumpStations",params)
