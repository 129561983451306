<!-- 操作记录 -->
<template>
  <div class="app">
    <div class="header">
      <div class="select">
        <span style="color: #606266">节制闸：</span>
        <el-select v-model="searchValue.siteName" placeholder="请选择节制闸">
          <el-option
            v-for="(item,indexList) in dinawei"
            :key="item.id"
            :label="indexList+1+'   '+item.siteName"
            :value="item.siteName"
          >
          </el-option>
        </el-select>
      </div>
      <div class="date">
        <span style="color: #606266">时间：</span>
        <el-date-picker
          class="dateTime"
          v-model="searchValue.value1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>

      <div class="btn-header">
        <el-button type="primary" @click="onSearch(searchValue)">查询</el-button>
        <el-button @click="Reset">重置</el-button>
      </div>
    </div>

    <div style="margin-top: 25px">
      <el-table :header-cell-style="{'background-color': '#4073b6', color: '#FFFFFF'}" :data="tableData" stripe
                style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="siteName" label="节制闸名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="操作类型" :formatter="carryOutTypeFlag" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operands" label="操作对象" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="操作前闸门高度" show-overflow-tooltip>
          <template slot-scope="spend">
            {{ spend.row.operationFront + 'cm' }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作后闸门高度" show-overflow-tooltip>
          <template slot-scope="spend">
            {{ spend.row.operationAfter + 'cm' }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="开始执行时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="examineTime" label="结束执行时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="gateHeight" label="设置高度" show-overflow-tooltip width="150">
          <template slot-scope="spend">
            {{ spend.row.gateHeight == null ? "" : spend.row.gateHeight + 'cm' }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="执行人" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="40%"
      top="0vh"
      :center="true"
      @close="cancel"
    >
      <template slot="title">
        <div
          style="font-size: 18px; font-weight: bold; color: #333333"
          v-show="boxruleForm.type == 1"
        >
          变更闸门高度申请
        </div>
        <div
          style="font-size: 18px; font-weight: bold; color: #333333"
          v-show="boxruleForm.type == 2"
        >
          变更布控状态申请
        </div>

        <!-- <el-button type="primary" @click="startSluice(1)">开启</el-button>
        <el-button @click="stopSluice(2)" style="margin: 0 5px">关闭</el-button>
        <el-button @click="stopSluice(3)">急停</el-button> -->
        <el-select v-model="boxruleForm.gateType" placeholder="请选择">
          <el-option key="1" label="开启" :value="1" v-if="boxruleForm.type == 1"></el-option>
          <el-option key="2" label="关闭" :value="2" v-if="boxruleForm.type == 1"></el-option>
          <el-option key="3" label="急停" :value="3" v-if="boxruleForm.type == 1"></el-option>
          <el-option key="4" label="开启布控" :value="4" v-if="boxruleForm.type == 2"></el-option>
          <el-option key="5" label="关闭布控" :value="5" v-if="boxruleForm.type == 2"></el-option>
        </el-select>
      </template>

      <el-form
        label-position="top"
        label-width="100px"
        :rules="boxrules"
        :model="boxruleForm"
        ref="boxForm"
      >
        <div class="Publishtask_center">
          <!-- <div class="Publishtask_title">
            <el-form-item label="操作闸门" prop="value">
              <el-select style="width: 100%" v-model="boxruleForm.value" @change="cutGate">
                <el-option v-for="item in gateOption" :key="item.id" :label="item.gateName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <div class="Publishtask_title" v-if="boxruleForm.gateType == 1">
            <el-form-item label="变更后高度" prop="gateHeight">
              <el-input
                style="width: 80%"
                v-model="boxruleForm.gateHeight"
                maxlength="20"
                placeholder="请输入闸门高度"
              ></el-input>
              （cm）
            </el-form-item>
          </div>
          <div class="Publishtask_title">
            <el-form-item
              label="执行方式"
              class="item_form"
              prop="carryOutType"
            >
              <el-radio-group
                @change="changeTheme"
                v-model="boxruleForm.carryOutType"
              >
                <el-radio :label="1">定时执行</el-radio>
                <el-radio :label="2">审批通过立即执行</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="Publishtask_title" v-if="timedstart">
            <el-form-item label="执行时间设置" prop="carryOutTime">
              <el-date-picker
                v-model="boxruleForm.carryOutTime"
                type="datetime"
                placeholder="选择日期时间"
                clearable
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="Publishtask_button">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="boxSave">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import router from "../router";
import openwindowone from "../views/openwindowone.vue"; // 开启
export default {
  name: "operationrecord",
  components: {
    openwindowone,
  },
  data() {
    return {
      rivergate: "",
      dataTimer: "",
      currentPage1: 1,
      total: 10,
      pageSize: 10,
      size: 10,
      options: [],
      tableData: [], // 数据
      siteName: "",
      OperationType: [
        {value: 1, label: "变更闸门高度",},
        {value: 2, label: "变更布控状态"},
      ],
      searchValue: {
        id: "",
        siteName: "",
        index: "",
        value: "",
        value1: "",
        startTime: "",
        endTime: "",
        type: 1,
      },
      dialogVisible: false,
      delFlag: false,
      obj: {},
      status: "",
      openwindowoneFlag: false,
      boxrules: {
        gateHeight: [
          // 闸门高度
          {
            required: true,
            message: "请输入闸门高度",
            trigger: "blur",
          },
          {
            min: 1,
            max: 3,
            message: "长度在 1 到 3 个字符",
            trigger: "blur",
          },
        ],
        carryOutTime: [
          // 定时执行时间
          {
            required: true,
            message: "请选择执行时间 ",
            trigger: "blur",
          },
        ],
        carryOutType: [
          // 定时执行 还是 审批之后执行
          {
            required: true,
            message: "请选择所属分组",
            trigger: "change",
          },
        ],
      },
      boxruleForm: {
        valHeight: "", // 变更高度
        parentId: "", // 定时执行 还是 审批之后执行
        dataTimer: "", // 定时执行时间
        value: "", // 操作闸门id
        siteId: null, // 当前站点id
        kaiqi: null, // 开启状态值
        operationFront: null,
      },
      gateOption: null, // 闸门列表
      timedstart: false, //
      id: "",
      radio: 1,
      dinawei: [],
    };
  },
  created() {
    this.getState()
  },
  mounted() {
    this.getData(this.currentPage1, this.pageSize, this.obj);
  },
  methods: {
    // 获取点位信息
    getState() {
      this.$get(`/site/page`, {
        page: 1,
        size: 999,
      }).then((res) => {
        if (res.code == 200) {
          this.dinawei = res.data.list
        }
      })
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {
        });
    },
    // 查询数据
    getData(currentPage1, pageSize, val) {
      this.$axios
        .get(`/applyRecord/selectApplyRecordListPage`, {
          params: {
            pageNum: currentPage1,
            pageSize: pageSize,
            type: 1,
            ...val,
          },
        })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total
          res.data.list.forEach((e) => {
            this.status = e.status;
          });
        });
    },
    // 搜索
    onSearch(val) {
      let obj = {
        startTime: val.value1 ? val.value1[0] : "",
        endTime: val.value1 ? val.value1[1] : "",
        siteName: this.searchValue.siteName,
        status: this.searchValue.index,
        type: 1
      }
      this.obj = obj;
      this.dangqianPage = 1;
      this.getData(this.dangqianPage, this.size, obj);
    },
    // 审批数据
    examineandapprove(row) {
      // this.dialogVisible = true;
      this.sure(row.id);
    },
    sure(id) {
      this.$confirm("是否通过该审批?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$axios.post(
          `/applyRecord/updateApplyRecordExamine`,
          {
            id: id,
            status: 2,
          }
        );
        console.log(res);
        if (res.code == 200) {
          this.Reset();
          // this.$message({
          //   message: "操作成功",
          //   type: "success",
          // });
          Vue.prototype.$message({
            message: res.message,
            type: "warning",
          });
        } else if (res.code == 500) {
          this.Reset();
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
        this.getData(this.dangqianPage, this.size, this.obj);
      });
    },
    // 编辑数据
    edit(row) {
      this.boxruleForm = row;
      console.log(this.boxruleForm);
      if (this.boxruleForm.carryOutType == 1) {
        this.timedstart = true;
      } else {
        this.timedstart = false;
      }

      if (this.boxruleForm.type == 1) {
      } else if (this.boxruleForm.type == 2) {
      }

      this.dialogVisible = true;
      // this.$axios.post(`/applyRecord/updateApplyRecord`,{}).then(res=>{
      //   console.log(res);
      // })
    },
    // 每页条数 ceshia
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    // 当前页
    handleCurrentChange(val) {
      console.log(val.value1)
      this.page = val
      let obj = {
        startTime: this.searchValue.value1 ? this.searchValue.value1[0] : "",
        endTime: this.searchValue.value1 ? this.searchValue.value1[1] : "",
        siteName: this.searchValue.siteName,
        status: this.searchValue.index,
        type: 1
      }
      this.obj = obj;
      this.getData(this.page, this.pageSize, obj);
    },
    // 删除数据
    Deleteitem(row) {
      console.log(row);
      this.delFlag = true;
      this.del(row.id);
    },
    // 删除
    del(id) {
      this.$confirm("此操作将永久删除用户信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$axios.post(
          `/applyRecord/deleteApplyRecord?id=` + id,
          {}
        );
        if (res.code == 200) {
          this.Reset();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
        this.getData(this.dangqianPage, this.size, this.obj);
      });
    },
    //  操作类型转换格式
    carryOutTypeFlag(row, column) {
      if (row.type == 1) {
        return "变更闸门高度 "
      } else if (row.type == 2) {
        return "变更布控状态 "
      }
    },
    // 重置
    Reset() {
      this.currentPage1 = 1
      this.getData(this.currentPage1, this.pageSize);
      this.searchValue = {}
    },
    statusFlag(row, column) {
      if (row.status == 1) {
        return "未审批";
      } else if (row.status == 2) {
        return "已审批";
      } else if (row.status == 3) {
        return "审批拒绝";
      }
    },
    // 取消
    cancel() {
      this.$refs.boxForm.resetFields();
      this.boxruleForm = {};
      this.dialogVisible = false;
    },
    // 切换闸门
    // cutGate (value) {
    //   console.log(value)
    //   // this.gateShow
    //   const id = value
    //   // 获取闸门 num
    //   for (const i of this.options) {
    //     if (i.id === id) {
    //       this.num = i.num
    //       i.num > 5 ? (this.gateShow = false) : (this.gateShow = true)
    //     }
    //   }
    //   this.getGateVoice()
    //   // 切换闸门信息
    //   getGateMessage({
    //     id,
    //     size: this.size,
    //     page: 1,
    //     num: this.num,
    //     siteId: this.siteId,
    //   }).then((res) => {
    //     this.gateVo = {}
    //     this.gateVo = res.data.gateVo
    //     // 每次重新清空
    //     this.videoList = res.data.videoInfo.list[0]
    //     this.total = res.data.videoInfo.total
    //     this.pageSize = res.data.videoInfo.pageSize
    //     setTimeout(() => {
    //       const video = document.querySelector('#videoList')
    //       if (video) {
    //         video.innerHTML = ''
    //         new this.$ezuikit.EZUIKitPlayer({
    //           autoplay: true,
    //           id: 'videoList',
    //           header: ['capturePicture', 'save'],
    //           footer: ['hd', 'fullScreen'],
    //           accessToken: this.videoList.accessToken,
    //           url: this.videoList.videoAddress,
    //           template: 'simple',
    //           audio: 0,
    //         })
    //       }
    //     }, 500)
    //   })
    // },
    // 获取定时执行还是审批之后立即执行
    changeTheme(val) {
      if (val == 1) {
        this.timedstart = true;
      } else {
        this.timedstart = false;
      }
    },
    // 保存提交申请
    boxSave(id) {
      console.log(id);
      // let params = {
      //   carryOutTime: this.boxruleForm.dataTimer, // 执行时间
      //   carryOutType: this.boxruleForm.parentId, // 执行类型
      //   gateHeight: this.boxruleForm.valHeight, // 修改闸门高度
      //   gateId: this.boxruleForm.value, // 闸门Id
      //   id:this.id,
      //   gateType: this.boxruleForm.kaiqi, // 操作类型详解 1开启 2关闭 3急停 4开启布控 5关闭布控
      //   operands: this.boxruleForm.value, // 操作对象
      //   operationFront: this.boxruleForm.beforeoperation, // 	操作前状态
      //   siteId: this.boxruleForm.siteId, // 站点id
      //   type: 1, // 变更闸门高度
      // }
      this.boxruleForm;
      this.$axios
        .post(`/applyRecord/updateApplyRecord`, this.boxruleForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success({message: "申请成功"});
            this.cancel();
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Publishtask_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .Publishtask_title {
    padding-top: 10px;
    width: 300px;

    /deep/ .el-form-item {
      width: 100% !important;
      margin: 10px !important;

      .el-form-item__content {
        .el-cascader {
          width: 100%;
        }

        /deep/ .el-form-item__error {
          left: 0 !important;
        }
      }
    }
  }

  .Publishtask_textarea {
    padding-top: 10px;
    width: 100%;
  }
}

.Publishtask_button {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

/deep/ .el-date-editor .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  width: 110% !important;
  height: 40px;
}

/deep/ .el-date-editor .el-input__icon {
  left: 5px !important;
  bottom: -1px;
}

/deep/ .el-date-editor .el-input__suffix {
  display: none !important;
}

.app {
  color: black;
  // 头部
  .header {
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    align-items: center;

    .select {
      padding-left: 10px;
    }

    .date {
      padding-left: 10px;

      .dateTime {
        width: 21.96875vw !important;
        /deep/.el-range-input{
          padding-left: 22px !important;
          width: 183px !important;
        }
      }

      /deep/ .el-input {
        top: -0px;
      }
    }

    .btn-header {
      padding-left: 50px;
    }

    .export {
      padding-left: 400px;
    }
  }

  //  内容区域
  .main-conter {
    width: 100%;
    //height: 100%;
    background-color: #fff;
    margin-top: 10px;

    .table {
      .paging {
        text-align: center;
      }
    }
  }
}
</style>
